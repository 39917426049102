<template>
  <v-row align="center" justify="center">
    <v-col cols="12">
      <v-card elevation="0" tile>
        <v-card-title class="grey lighten-4">
          {{ $t("hr.menu.vehicles") }}
          <v-spacer />
          <id-button @click="onRefreshData">
            <template #icon>
              <v-icon left>mdi-refresh</v-icon>
            </template>
            {{ $t("buttons.refresh") }}
          </id-button>
        </v-card-title>
        <v-data-table
          :headers="table.headers"
          :items="table.data"
          :options.sync="table.options"
          :server-items-length="table.totalItems"
          single-select
          :loading="table.loading"
          :footer-props="{ 'items-per-page-options': [10, 25, 50, 100] }"
          class="elevation-1"
        >
          <template slot="item" slot-scope="row">
            <tr :active="row.selected">
              <td width="2%" class="pr-1">
                <v-avatar :color="row.item.color" size="35">
                  <span class="white--text font-weight-bold">
                    {{ row.item.plateNumber.substring(0, 2).toUpperCase() }}
                  </span>
                </v-avatar>
              </td>
              <td>
                {{ row.item.plateNumber || "-" }}
              </td>

              <td>
                {{ row.item.vehicleModel.vehicleBrand.name }}
              </td>
              <td>
                {{ row.item.vehicleModel.name }}
              </td>
              <td>
                {{ row.item.year }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  /**
   * @author Ali Hüseyin ŞİRELİ
   * @email ali.sireli@globme.com.tr
   * @create date 2021-01-07 08:25:00
   * @modify date 2021-01-07 08:25:00
   * @desc Vehicles
   */
  import { VEHICLES } from "../../query";

  export default {
    name: "Vehicles",
    data: vm => ({
      table: {
        loading: false,
        data: [],
        criteria: {
          employee: null
        },
        options: {},
        pagination: {
          rowsPerPage: 10,
          sortBy: "plateNumber",
          descending: false
        },
        totalItems: 0,
        totalPages: 0,
        selected: [],
        headers: [
          {
            text: "",
            value: "",
            sortable: false
          },
          {
            text: vm.$t("hr.vehicle.plate_number"),
            align: "left",
            sortable: true,
            value: "plateNumber",
            class: ["primary--text"]
          },

          {
            text: vm.$t("hr.vehicle.brand"),
            align: "left",
            sortable: true,
            value: "vehicleBrand",
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.vehicle.model"),
            align: "left",
            sortable: true,
            value: "vehicleModel",
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.vehicle.year"),
            align: "left",
            sortable: true,
            value: "year",
            class: ["primary--text"]
          }
        ]
      }
    }),
    watch: {
      "table.options": {
        handler(options) {
          let { itemsPerPage, page } = options;
          this.table.pagination.rowsPerPage = itemsPerPage;
          this.table.pagination.page = page;
          this.table.criteria.employee = this.$store.state.auth.user.id;
          this.fetchItems();
        },
        deep: true
      }
    },
    methods: {
      onRefreshData() {
        this.fetchItems();
      },
      async fetchItems() {
        this.table.loading = true;
        await this.$apollo
          .query({
            query: VEHICLES,
            variables: {
              criteria: this.table.criteria,
              pagination: this.$helpers.convertPagination(this.table.pagination)
            }
          })
          .then(({ data, errors }) => {
            if (!errors || !data.error) {
              this.table.data = data.filterWithPaginateVehicles.content || [];
              this.table.totalItems = data.filterWithPaginateVehicles.pageInfo.totalElements || 0;
            }
          })
          .catch(e => {
            this.$helpers.showNotification(e.message);
          })
          .finally(() => (this.table.loading = false));
      }
    }
  };
</script>

<style scoped></style>
